/* eslint-disable react/jsx-key */
import { Datagrid, DateField, List, TextField } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { OtaDevicesListActions } from './ui/actions';

export const OtaDevicesList = () => {
  return (
    <List actions={<OtaDevicesListActions />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.id} sortable={false} />
        <TextField source="serialNumber" label={LABELS.serialNumber} />
        <TextField source="mac" label={LABELS.mac} sortable={false} />
        <TextField source="modelName" label={LABELS.modelName} sortable={false} />
        <TextField source="firmwareVersion" label={LABELS.firmware} sortable={false} />
        <TextField source="hardwareVersion" label={LABELS.hardwareVersion} sortable={false} />
        <TextField source="os" label={LABELS.os} sortable={false} />

        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="updatedAt" label={LABELS.updatedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
      </Datagrid>
    </List>
  );
};
