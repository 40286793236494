import React, { useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledTab = styled(Tab)`
  text-transform: none;
`;

export const CustomTabs = ({
  onApply,
  value,
  onChange,
}: {
  onApply: (filters: { isReviewed: boolean | undefined }) => void;
  value: number;
  onChange: (value: number) => void;
}) => {
  useEffect(() => {
    let filter: { isReviewed: boolean | undefined } = { isReviewed: undefined };
    if (value === 1) {
      filter = { isReviewed: true };
    }
    if (value === 2) {
      filter = { isReviewed: false };
    }
    onApply(filter);
  }, [value]);

  return (
    <StyledTabs value={value} onChange={(event, value) => onChange(value)}>
      <StyledTab label="Все" />
      <StyledTab label="Проверено" />
      <StyledTab label="Не проверено" />
    </StyledTabs>
  );
};
