import {
  DeleteWithConfirmButton,
  EditButton,
  Loading,
  Show,
  useGetOne,
  usePermissions,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import { Permissions, ResourseNames, Roles } from 'types';
import { UserShowActions } from './ui/actions';
import { UserShowContent } from './ui/content';
import { hasPermission } from '../../../validators';

export const UserShow = () => {
  const { id } = useParams();

  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  const { data, isLoading } = useGetOne(
    ResourseNames.users,
    { id: id as string },
    { onError: () => redirect('/users') },
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const pageTitle = `${data?.phone} ${data?.username || ''}`;

  return (
    <Show
      title={pageTitle}
      actions={
        <UserShowActions
          extra={[
            <EditButton key="edit-btn" label="Редактировать" disabled={!hasPermission(role, Permissions.EditUsers)} />,
            <DeleteWithConfirmButton
              disabled={!hasPermission(role, Permissions.DeleteUsers)}
              confirmTitle="Удаление"
              confirmContent="Удалить юзера?"
              key="delete-btn"
              label="Удалить"
            />,
          ]}
        />
      }>
      <UserShowContent data={data} />
    </Show>
  );
};
