export const getFileKeyFromUrl = (url?: string): string | null => {
  if (typeof url !== 'string' || !url) {
    console.error('URL is not in string', url);
    return null;
  }

  // Create a URL object to easily manipulate the URL
  let parsedUrl: URL;
  try {
    parsedUrl = new URL(url);
  } catch (e) {
    return null; // Return null if the URL is invalid
  }

  // Remove the query parameters by using the pathname
  const cleanUrl = parsedUrl.pathname;

  // Return everything after the domain as the file key
  const fileKey = cleanUrl.startsWith('/') ? cleanUrl.substring(1) : cleanUrl;

  return fileKey || null; // Return the file key or null if no path is found
};
