import { regex, required } from 'react-admin';
import { REGEX } from 'shared/constants/regex';
import { rolePermissions, Roles, Permissions } from '../../types';

export const isRequired = required('Обязательное поле');
export const isPasswordValid = regex(REGEX.fullPassword, 'Некорректный пароль');
export const isNameValid = regex(REGEX.name, 'Некорректное имя');

export const hasPermission = (role: Roles, permission: Permissions): boolean => {
  const permissionsForRole = rolePermissions[role];

  // Проверяем, есть ли такая роль в rolePermissions
  if (!permissionsForRole) {
    return false; // Возвращаем false, если роль не найдена
  }

  return permissionsForRole.includes(permission);
};

export const isFirmwareVersion = (value: string) => {
  const standardSemverRegex = /^\d+\.\d+\.\d+$/; // Стандартный семантический формат (e.g. 1.0.0)
  const customSemverRegex = /^\d+\.\d{2}\.\d{2}$/; // Кастомный формат (e.g. 1.00.01)

  if (!value) {
    return 'Required';
  }
  if (!standardSemverRegex.test(value) && !customSemverRegex.test(value)) {
    return `Invalid version format. Version (\`${value}\`) must be in the format MAJOR.MINOR.PATCH (e.g. 1.0.0 or 1.00.01)`;
  }
  return undefined;
};

export const isHardwareVersion = (value: string) => {
  const minorOnlySemverRegex = /^\d+\.\d+$/;
  const customSemverRegex = /^\d+\.\d{2}\.\d{2}$/; // Кастомный формат (e.g. 1.00.01)

  if (!value) {
    return 'Required';
  }
  if (!minorOnlySemverRegex.test(value) && !customSemverRegex.test(value)) {
    return `Invalid version format. Version (\`${value}\`) must be in the format MAJOR.MINOR or MAJOR.MINOR.PATCH (e.g. 1.0 or 1.00.01)`;
  }
  return undefined;
};
