import React, { forwardRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { IconButton, Slider } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

const StyledCropper = styled.div`
  position: relative;
  width: 80vw;
  height: 80vh;
  margin: auto;
  background: #fff;
  & .reactEasyCrop_CropArea {
    width: calc(100% - 2px) !important;
    height: calc(100% - 2px) !important;
    border-radius: 0;
  }
  & .reactEasyCrop_CropArea::before,
  & .reactEasyCrop_CropArea::after {
    display: none;
  }
`;

const StyledSliderContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
`;

interface ImageCropperProps {
  imageUrl: string;
  onClose: () => void;
}

export const ImageCropper = forwardRef<HTMLDivElement, ImageCropperProps>(({ imageUrl, onClose }, ref) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  return (
    <StyledCropper ref={ref} tabIndex={1}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Cropper
        image={imageUrl}
        crop={crop}
        cropShape={'round'}
        zoom={zoom}
        aspect={4 / 3}
        onCropChange={setCrop}
        onZoomChange={setZoom}
      />
      <StyledSliderContainer>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={(e, zoom) => setZoom(zoom as number)}
          style={{ width: 200 }}
        />
      </StyledSliderContainer>
    </StyledCropper>
  );
});
