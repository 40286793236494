import { List, useListContext } from 'react-admin';
import { Typography } from '@mui/material';
import { CustomCard } from './ui/custom-card';
import styled from 'styled-components';
import { CustomListActions } from './ui/actions';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const Container = () => {
  const { data, isLoading } = useListContext();

  if (isLoading) return <Typography>Загрузка...</Typography>;
  if (!data || data.length === 0) return <Typography>Нет записей</Typography>;

  return (
    <>
      {data.map(record => (
        <CustomCard key={record.id} record={record} />
      ))}
    </>
  );
};
export const WarrantiesList = () => {
  return (
    <List actions={<CustomListActions />} component={({ children }) => <ListContainer>{children}</ListContainer>}>
      <Container />
    </List>
  );
};
