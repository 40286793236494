import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from '../../config';
import { API_URL } from '../../constants';

export async function deleteFile(firmwareId: string): Promise<boolean> {
  try {
    await httpClient(`${API_BASE_URL_ADMIN}${API_URL.deleteFirmwareFile(firmwareId)}`, {
      method: 'DELETE',
    });
  } catch (error: any) {
    console.error('Error deleting file:', error);

    return false;
  }

  return true;
}
