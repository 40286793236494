import { httpClient } from '../../services/http-client';

export async function getFileMeta(fileKeyUrl: string) {
  try {
    const res = await httpClient(fileKeyUrl, {
      method: 'HEAD',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    if (res.status === 200) {
      return res.headers; // Возвращаем заголовки, если файл существует
    } else {
      console.warn('File not found or inaccessible');
      return null;
    }
  } catch (error: any) {
    console.error('Error getting file meta:', error);
    return null;
  }
}
