import styled from 'styled-components';
import { TextField } from '@mui/material';
import React, { useEffect } from 'react';

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 24px;
`;

const StyledTextField = styled(TextField)`
  && {
    width: 300px;
    background-color: #ffffff;
    border-radius: 8px;

    .MuiOutlinedInput-root {
      border-radius: 8px;
      height: 42px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #eef3f7;
    }

    .MuiInputBase-input {
      color: #4f4f4f;
    }
  }
`;

export const SearchBar = ({
  onApply,
  value,
  onChange,
}: {
  value: string;
  onApply: (data: { searchQuery: string }) => void;
  onChange: (e: any) => void;
}) => {
  useEffect(() => {
    onApply({ searchQuery: value });
  }, [value]);

  return (
    <SearchWrapper>
      <StyledTextField placeholder="Поиск" variant="outlined" value={value} onChange={onChange} />
    </SearchWrapper>
  );
};
