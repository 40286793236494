import { BooleanInput, Edit, Loading, SimpleForm, TextInput, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { LABELS } from 'shared/constants/labels';
import { ResourseNames } from 'types';
import { HardwareEditToolbar } from './ui/custom-toobar';
import { isHardwareVersion, isRequired } from '../../../validators';

export const HardwareEdit = () => {
  const { id } = useParams();
  const { data: hardware, isLoading } = useGetOne(ResourseNames.hardwares, {
    id,
  });
  const isDeleted = Boolean(hardware?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!hardware) return null;

  return (
    <Edit>
      <SimpleForm defaultValues={{ isDeleted }} toolbar={<HardwareEditToolbar />}>
        <TextInput source="version" validate={[isRequired, isHardwareVersion]} />
        <TextInput source="model.name" disabled={true} />
        <BooleanInput source="isDeleted" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
