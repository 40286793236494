import React, { useEffect, useState } from 'react';
import { TextField, Popover, Select, MenuItem, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import styled from 'styled-components';

const StyledDatePopperWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  && {
    width: 180px;
    background-color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    height: 42px;

    .MuiSelect-icon {
      color: #4f4f4f;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ffffff;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #eef3f7;
    }
  }
`;

const PopoverContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledDatePicker = styled(DatePicker)`
  && .MuiOutlinedInput-root {
    height: 42px;
    border-radius: 8px;
    background-color: #ffffff;
  }
  && .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ffffff;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #eef3f7;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  && {
    text-transform: none;
    font-size: 14px;
    height: 42px;
    padding: 0 20px;
  }
`;

export const DatePopper = ({
  onApply,
  onReset,
  valueFrom,
  valueTo,
}: {
  onApply: (filters: { purchaseDateFrom?: Date | null; purchaseDateTo?: Date | null }) => void;
  onReset: () => void;
  valueFrom: Date | null;
  valueTo: Date | null;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [purchaseDateFrom, setPurchaseDateFrom] = useState<Date | null>(null);
  const [purchaseDateTo, setPurchaseDateTo] = useState<Date | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPurchaseDateFrom(valueFrom);
    setPurchaseDateTo(valueTo);
  }, [valueFrom, valueTo]);

  const handleApply = () => {
    const filters = {
      purchaseDateFrom,
      purchaseDateTo,
    };
    onApply(filters);
    handleClose();
  };

  const handleReset = () => {
    setPurchaseDateFrom(null);
    setPurchaseDateTo(null);
    onReset();
    handleClose();
  };

  const open = Boolean(anchorEl);

  const formatDate = (date: Date | null) => (date ? date.toISOString().split('T')[0] : ''); // Format as YYYY-MM-DD

  const displayDate = () =>
    purchaseDateFrom && purchaseDateTo
      ? `${formatDate(purchaseDateFrom)} - ${formatDate(purchaseDateTo)}`
      : purchaseDateFrom
      ? `С: ${formatDate(purchaseDateFrom)}`
      : purchaseDateTo
      ? `По: ${formatDate(purchaseDateTo)}`
      : 'Выберите дату';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <StyledDatePopperWrapper>
        <StyledSelect value={displayDate()} readOnly displayEmpty onClick={handleOpen}>
          <MenuItem value={displayDate()}>{displayDate()}</MenuItem>
        </StyledSelect>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <PopoverContent>
            <StyledDatePicker
              label="Дата покупки с"
              value={purchaseDateFrom}
              maxDate={purchaseDateTo}
              onChange={(value: any) => setPurchaseDateFrom(value)}
              renderInput={params => <TextField {...params} />}
            />
            <StyledDatePicker
              label="Дата покупки по"
              value={purchaseDateTo}
              minDate={purchaseDateFrom}
              onChange={(value: any) => setPurchaseDateTo(value)}
              renderInput={params => <TextField {...params} />}
            />
            <ButtonContainer>
              <StyledButton variant="outlined" onClick={handleReset}>
                Сбросить
              </StyledButton>
              <StyledButton variant="contained" onClick={handleApply}>
                Применить
              </StyledButton>
            </ButtonContainer>
          </PopoverContent>
        </Popover>
      </StyledDatePopperWrapper>
    </LocalizationProvider>
  );
};
