import { httpClient } from 'shared/services/http-client';
import { API_BASE_URL_ADMIN } from '../../config';
import { API_URL } from '../../constants';
import { getUrl } from '../get-url';

export async function getSignedUrl(fileKey: string): Promise<string> {
  try {
    const response = await httpClient(`${API_BASE_URL_ADMIN}${API_URL.getFirmwareFileSignedLInk}`, {
      method: 'POST',
      body: JSON.stringify({ fileKey }),
    });

    return getUrl(JSON.parse(response.body).fileUrl);
  } catch (error: any) {
    throw new Error(error.message || `Failed to get signed link file, ${fileKey}`);
  }
}
