import { Datagrid, DateField, List, ShowButton, TextField, usePermissions } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { ModelsListActions } from './ui/actions';
import { Permissions, Roles } from '../../../../types';
import { hasPermission } from '../../../validators';

export const ModelsList = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  return (
    <List actions={<ModelsListActions isCreateDisabled={!hasPermission(role, Permissions.CreateModels)} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.modelId} sortable={false} />
        <TextField source="name" label={LABELS.modelName} sortable={false} />
        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
