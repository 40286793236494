import { BooleanInput, Edit, Loading, SimpleForm, TextInput, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { LABELS } from 'shared/constants/labels';
import { ResourseNames } from 'types';

export const DeviceEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetOne(ResourseNames.devices, { id });

  const isDeleted = Boolean(data?.deletedAt);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  return (
    <Edit>
      <SimpleForm defaultValues={{ isDeleted }}>
        <TextInput source="name" />
        <TextInput source="alias" />
        <TextInput source="mac" />
        <TextInput source="serialNumber" />
        <TextInput source="hardware.id" />
        <TextInput source="firmware.id" />
        <TextInput source="user.id" />
        <BooleanInput source="isDeleted" label={LABELS.isDeleted} helperText="С возможностью восстановить" />
      </SimpleForm>
    </Edit>
  );
};
