import { Create, Loading, SelectInput, SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';
import { httpClient } from 'shared/services/http-client';
import { isHardwareVersion, isRequired } from '../../../validators';
import { LABELS } from '../../../constants';
import { useHttpClient } from '../../../hooks/useHttpClient';
import { AdminModelResponseDto } from '../../../api';

export const HardwareCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { data, isLoading } = useHttpClient<AdminModelResponseDto>(API_URL.getModels);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const modelOptions = data.data.map((model: any) => ({
    id: model.id,
    name: model.name,
  }));

  const handleSubmit = async (data: any) => {
    try {
      await httpClient(`${API_BASE_URL_ADMIN}${API_URL.createHardwares}`, {
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
      });

      notify('Hardware добавлено', { type: 'success' });
      redirect('/hardwares');
    } catch (error: any) {
      if (error.message) {
        notify(
          `Что-то пошло не так: ${error?.message}
        `,
          { type: 'error' },
        );
      } else {
        notify('Что-то пошло не так...', { type: 'error' });
      }
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="version" validate={[isRequired, isHardwareVersion]} />
        <SelectInput
          source="modelId"
          validate={[isRequired]}
          label={LABELS.modelName}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
          choices={modelOptions}
          optionText="name"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
