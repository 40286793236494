import { BooleanField, Datagrid, DateField, EditButton, List, TextField, usePermissions } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { FeatureToggleListActions } from './ui/actions';
import { hasPermission } from '../../../validators';
import { Permissions, Roles } from '../../../../types';

export const FeatureToggleList = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  return (
    <List actions={<FeatureToggleListActions />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.featureToggleId} sortable={false} />
        <TextField source="name" label={LABELS.featureToggleName} sortable={false} />
        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="updatedAt" label={LABELS.updatedAt} sortable={true} />
        <BooleanField source="enabled" label={LABELS.isFeatureToggleEnabled} sortable={true} />
        <EditButton disabled={!hasPermission(role, Permissions.EditFeatureToggles)} />
      </Datagrid>
    </List>
  );
};
