import { TopToolbar, useListContext } from 'react-admin';
import styled from 'styled-components';
import { Filters } from './filters';
import { CustomTabs } from './custom-tabs';
import React, { useCallback, useState } from 'react';
import { SearchBar } from './search-bar';

const StyledTopToolbar = styled(TopToolbar)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const TabsAndFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

export const CustomListActions = () => {
  const { setFilters } = useListContext();
  const [currentFilters, setCurrentFilters] = useState<object>({});
  const [tabValue, setTabValue] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleApplyFilters = async filters => {
    try {
      setCurrentFilters({ ...currentFilters, ...filters });
      setFilters({ ...currentFilters, ...filters }, {});
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  const handleResetFilters = useCallback(() => {
    setCurrentFilters({});
    setFilters({}, {});
    setTabValue(0);
    setSearchValue('');
  }, []);

  const handleTabChange = useCallback((value: number) => {
    setTabValue(value);
  }, []);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  return (
    <StyledTopToolbar>
      {/* Поисковая строка в правом верхнем углу */}
      <SearchBarWrapper>
        <SearchBar onApply={handleApplyFilters} value={searchValue} onChange={handleSearchChange} />
      </SearchBarWrapper>

      {/* Tabs и фильтры */}
      <TabsAndFiltersWrapper>
        <CustomTabs onApply={handleApplyFilters} value={tabValue} onChange={handleTabChange} />
        <Filters onApply={handleApplyFilters} onReset={handleResetFilters} />
      </TabsAndFiltersWrapper>
    </StyledTopToolbar>
  );
};
