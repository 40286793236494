import { BooleanField, Datagrid, DateField, List, ShowButton, TextField, UrlField } from 'react-admin';
import { LABELS } from '../../../../constants';
import React from 'react';

export const MetaBetaAgreementList = () => {
  return (
    <List>
      <Datagrid bulkActionButtons={false}>
        <TextField source="phone" label={LABELS.phone} sortable={false} />
        <TextField source="fullName" label={LABELS.fullName} sortable={false} />
        <DateField source="birthDate" label={LABELS.dateOfBirth} sortable={false} />
        <TextField source="passportSeriesAndNumber" label={LABELS.passportSeriesAndNumber} sortable={false} />
        <TextField source="registrationAddress" label={LABELS.registrationAddress} sortable={false} />
        <TextField source="issuedBy" label={LABELS.issuedBy} sortable={false} />
        <DateField source="issuedDate" label={LABELS.issuedDate} sortable={false} />
        <BooleanField source="nonDisclosureAgreement" label={LABELS.nonDisclosureAgreement} sortable={false} />
        <BooleanField
          source="dataProcessingAndPrivacyConsent"
          label={LABELS.dataProcessingAndPrivacyConsent}
          sortable={false}
        />
        {/* Используем кастомный компонент */}
        <UrlField
          source="documentPhotoUrl"
          label={LABELS.documentPhotoUrl}
          style={{
            whiteSpace: 'nowrap', // Предотвращает перенос текста на следующую строку
            overflow: 'hidden', // Скрывает текст, выходящий за пределы контейнера
            textOverflow: 'ellipsis', // Добавляет троеточие для длинных текстов
            maxWidth: 200, // Максимальная ширина колонки
            display: 'block', // Убедитесь, что стили применяются корректно
          }}
        />
        <DateField source="createdAt" label={LABELS.addedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
      </Datagrid>
    </List>
  );
};
