export const API_URL = {
  createFirmware: '/firmwares',
  uploadFirmwareFile: '/firmwares/file/upload_link',
  getFirmwareFileSignedLInk: '/firmwares/file/get_link',
  deleteFirmwareFile: (firmwareId: string) => `/firmwares/${firmwareId}/file/remove`,
  moveFirmwareFile: (firmwareId: string) => `/firmwares/${firmwareId}/file/move`,
  updateFirmware: (firmwareId: string) => `/firmwares/${firmwareId}`,
  getHardwares: '/hardwares',
  getHardware: (id: string) => `/hardwares/${id}`,
  getCartridges: '/cartridges',
  getModels: '/models',
  createCartridges: '/cartridges',
  createDevices: '/devices',
  createHardwares: '/hardwares',
  createModels: '/models',
  createUsers: '/users',
  updateCartridge: (deviceId: string) => `/cartridges/${deviceId}`,
  updateFeatureToggle: (toggleId: string) => `/feature-toggle/${toggleId}`,
  getFeatureToggles: '/feature-toggle',
  getFeatureToggle: (toggleId: string) => `/feature-toggle/${toggleId}`,
  getMetaSmartWarranties: '/warranties/meta-smart',
  getMetaLiteWarranties: '/warranties/meta-lite',
  updateWarranty: (id: string) => `/warranties/${id}`,
};
