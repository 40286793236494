import { AuthProvider } from 'react-admin';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { StorageKeys } from 'types';

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    const request = new Request(`${API_BASE_URL_ADMIN}/auth/sign-in`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(async response => {
        const result = await response.json();

        if (response.status < 200 || response.status >= 300) {
          throw new Error(result.message);
        }

        return result;
      })
      .then(result => {
        localStorage.setItem(StorageKeys.token, result.token);
        localStorage.setItem(StorageKeys.permissions, result.role);
      })
      .catch(error => {
        throw new Error(error.message || 'Login failed');
      });
  },
  logout: () => {
    localStorage.removeItem(StorageKeys.token);
    localStorage.removeItem(StorageKeys.permissions);
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem(StorageKeys.token) ? Promise.resolve() : Promise.reject()),
  checkError: (error: any) => {
    const status = error?.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem(StorageKeys.token);
      localStorage.removeItem(StorageKeys.permissions);

      return Promise.reject(); // This will trigger redirection to the login page.
    }

    return Promise.resolve(); // For other errors, we don't log out the user.
  },
  getIdentity: () =>
    Promise.resolve({
      id: 'user',
      fullName: 'John Doe',
    }),

  getPermissions: () => {
    const role = localStorage.getItem(StorageKeys.permissions);
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
