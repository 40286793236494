import { Menu, usePermissions } from 'react-admin';
import { Roles, Permissions } from 'types';
import { hasPermission } from '../../validators';

const menuItems = [
  { permission: Permissions.ReadAdmins, to: '/admins', primaryText: 'Админы' },
  { permission: Permissions.ReadUsers, to: '/users', primaryText: 'Пользователи' },
  { permission: Permissions.ReadDevices, to: '/devices', primaryText: 'Устройства' },
  { permission: Permissions.ReadFirmwares, to: '/firmwares', primaryText: 'Прошивки' },
  { permission: Permissions.ReadHardwares, to: '/hardwares', primaryText: 'Hardware' },
  { permission: Permissions.ReadCartridges, to: '/cartridges', primaryText: 'Картриджи' },
  { permission: Permissions.ReadModels, to: '/models', primaryText: 'Модели' },
  { permission: Permissions.ReadOtaDevices, to: '/ota/devices', primaryText: 'OTA устройства' },
  {
    permission: Permissions.ReadAgreements,
    to: '/agreements/flavors-test',
    primaryText: 'Соглашения тестирования вкусов',
  },
  {
    permission: Permissions.ReadAgreements,
    to: '/agreements/meta-beta',
    primaryText: 'Соглашения бета тестирования Meta',
  },
  { permission: Permissions.ReadWarranties, to: '/warranties/meta-lite', primaryText: 'Гарантии Meta Lite' },
  { permission: Permissions.ReadWarranties, to: '/warranties/meta-smart', primaryText: 'Гарантии Meta Smart' },
  { permission: Permissions.ReadFeatureToggles, to: '/feature-toggle', primaryText: 'Управление фичами' },
];

export const SidebarMenu = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  if (!role) return null;

  return (
    <Menu>
      {menuItems.map(
        item =>
          hasPermission(role, item.permission) && (
            <Menu.Item key={item.to} to={item.to} primaryText={item.primaryText} />
          ),
      )}
    </Menu>
  );
};
