import React from 'react';
import { Admin } from 'react-admin';
import { resources } from 'resources';
import crudProvider from '@fusionworks/ra-data-nest-crud';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { httpClient } from 'shared/services/http-client';
import { SidebarMenu } from 'shared/widgets/sidebar-menu';
import { authProvider } from 'providers';
import { SignInPage } from 'pages/sign-in';
import { Roles } from './types';
import { DefaultDashboard } from './shared/widgets/default-dashboard';

const dataProvider = crudProvider(API_BASE_URL_ADMIN, httpClient);

export const App = () => {
  return (
    <Admin
      loginPage={SignInPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      menu={SidebarMenu}
      dashboard={DefaultDashboard}>
      {(role: Roles) => <>{resources(role).map(item => item)}</>}
    </Admin>
  );
};
