import { httpClient } from 'shared/services/http-client';
import { getUrl } from '../get-url';
import { API_BASE_URL_ADMIN } from '../../config';
import { API_URL } from '../../constants';

export async function moveFile(firmwareId: string, hardwareId: string, firmwareVersion: string) {
  const body = JSON.stringify({
    hardwareId,
    firmwareVersion,
  });

  try {
    const res = await httpClient(`${API_BASE_URL_ADMIN}${API_URL.moveFirmwareFile(firmwareId)}`, {
      method: 'PATCH',
      body,
    });

    return { fileKey: getUrl(JSON.parse(res.body).fileKey) };
  } catch (error: any) {
    throw new Error(error.message || 'Failed to move file');
  }
}
