import { FC } from 'react';
import { Tab, TabbedShowLayout, TextField, DateField } from 'react-admin';
import { AdminDeviceResponseDto } from 'shared/api';
import { APP_BASE_URL } from 'shared/config';
import { LABELS } from 'shared/constants/labels';

interface Props {
  data?: AdminDeviceResponseDto;
}

export const DeviceShowContent: FC<Props> = ({ data }) => {
  return (
    <TabbedShowLayout>
      <Tab label="Основная информация">
        <TextField source="id" label={LABELS.id} />
        <TextField source="name" label={LABELS.name} />
        <TextField source="mac" label={LABELS.mac} />
        <TextField source="serialNumber" label={LABELS.serialNumber} />
        <TextField source="alias" label={LABELS.alias} />
        {data?.user && (
          <div>
            <div
              style={{ fontFamily: 'sans-serif', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '3px' }}>
              Пользователь
            </div>
            <a href={`${APP_BASE_URL}/users/${data?.user.id}/show`}>
              <TextField source="user.username" label={LABELS.user} />
            </a>
          </div>
        )}
        <div>
          <div style={{ fontFamily: 'sans-serif', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '3px' }}>
            Hardware
          </div>
          <a href={`${APP_BASE_URL}/hardwares/${data?.hardware.id}/show`}>
            <TextField source="hardware.version" label={LABELS.hardware} />
          </a>
        </div>
        <div>
          <div style={{ fontFamily: 'sans-serif', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '3px' }}>
            Версия прошивки
          </div>
          <a href={`${APP_BASE_URL}/firmwares/${data?.firmware.id}/show`}>
            <TextField source="firmware.version" label={LABELS.firmware} />
          </a>
        </div>
        <DateField source="createdAt" label={LABELS.addedAt} />

        {data?.deletedAt && <DateField source="deletedAt" label={LABELS.deletedAt} />}
      </Tab>
    </TabbedShowLayout>
  );
};
