import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Card, Typography, Button, IconButton, Menu, MenuItem, TextField, Chip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDelete, useNotify, useUpdate } from 'react-admin';
import { ImageCropper } from './image-cropper';
import { ReceiptPhotoPreview } from './receipt-photo-preview';

// Styled Components
const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;

  .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledButtonsWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  gap: 8px;
  top: -30px;
  left: 20px;
  z-index: 1;
  .MuiButtonBase-root {
    font-size: 12px;
  }
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`;

const StyledCheckedButton = styled(Button)`
  background-color: ${(props: { isChecked: boolean }) => (props.isChecked ? '#BDF294' : 'transparent')};
  color: ${(props: { isChecked: boolean }) => (props.isChecked ? '#323232' : '#8A8890')};
  border: 1px solid ${(props: { isChecked: boolean }) => (props.isChecked ? '#BDF294' : '#8A8890')};
  text-transform: none;
  padding: 12px 20px;
  border-radius: 8px;
`;
const StyledUnCheckedButton = styled(Button)`
  background-color: ${(props: { isChecked: boolean }) => (!props.isChecked ? '#FFD6D6' : 'transparent')};
  color: ${(props: { isChecked: boolean }) => (!props.isChecked ? '#323232' : '#8A8890')};
  border: 1px solid ${(props: { isChecked: boolean }) => (!props.isChecked ? '#FFD6D6' : '#8A8890')};
  text-transform: none;
  padding: 12px 20px;
  border-radius: 8px;
`;
const StatusWrapper = styled.div``;

const StatusChip = styled(Chip)`
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  padding: 16px 20px;
  background-color: ${(props: { isActive: boolean }) => (props.isActive ? '#BDF294' : '#F5F5F7')} !important;
  color: ${(props: { isActive: boolean }) => (props.isActive ? '#323232' : '#8A8890')} !important;
  border: 1px solid ${(props: { isActive: boolean }) => (props.isActive ? '#BDF294' : '#8A8890')};
  width: 100%;
`;

const StyledThreeDotWrapper = styled(IconButton)``;

const CardContentWrapper = styled(Card)<{ isDisabled: boolean }>`
  display: flex;
  border-color: #dadada;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
  gap: 16px;
  box-shadow: none;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
`;

const StyledCardActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 150px;
  max-width: 300px;
  height: auto;
  width: auto;
`;

const ColumnShort = styled(Column)`
  flex-grow: 0.5;
  min-width: 100px;
  max-width: 200px;
`;

const ColumnLong = styled(Column)`
  flex-grow: 2.5;
  max-width: 400px;
`;

const StyledTypography = styled(Typography)`
  margin: 0;
  padding: 0;
`;

const CommentInput = styled(TextField)`
  width: 100%;

  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: #ffffff;
    font-size: 12px;

    & fieldset {
      border: 1px solid #dadada;
    }

    &:hover fieldset {
      border-color: #888888;
    }

    &.Mui-focused fieldset {
      border-color: #888888; /* Акцент на фокусе */
    }
  }
`;

export const CustomCard = ({ record }: { record: any }) => {
  const notify = useNotify();
  const [update, { isLoading }] = useUpdate();
  const [deleteOne] = useDelete();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isImageCropperOpen, setImageCropperOpen] = useState(false);
  const cropperRef = useRef<HTMLDivElement>(null);

  // Trigger focus when the cropper is opened
  useEffect(() => {
    if (isImageCropperOpen && cropperRef.current) {
      // Focus on the first input or the cropper container when it opens
      console.log(cropperRef.current);
      cropperRef.current.focus();
    }
  }, [isImageCropperOpen]);

  if (!record) return null;

  const open = Boolean(anchorEl);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCardUpdate = async (
    id: string,
    data: { isReviewed?: boolean; comments?: string | null; isDeleted?: boolean },
  ) => {
    try {
      // Perform the update operation using useUpdate
      await update('warranties', { id, data }, { mutationMode: 'optimistic' });
    } catch (error) {
      // Handle any unexpected errors
      notify('Что-то пошло не так...', { type: 'error' });
    }
  };

  const handleDelete = async (id: string, record: any) => {
    try {
      await deleteOne('warranties', { id, previousData: record });
    } catch (error) {
      notify('Что-то пошло не так...', { type: 'error' });
    }
    setAnchorEl(null);
  };

  return (
    <StyledCardWrapper>
      {/* Кнопки Проверено/Не проверено */}
      <StyledCardActionsWrapper>
        <StyledButtonsWrapper isDisabled={!!record.deletedAt}>
          <StyledUnCheckedButton
            isChecked={record.isReviewed}
            onClick={() => handleCardUpdate(record.id, { isReviewed: false })}
            disabled={isLoading}
            size="small">
            Не проверено
          </StyledUnCheckedButton>
          <StyledCheckedButton
            isChecked={record.isReviewed}
            onClick={() => handleCardUpdate(record.id, { isReviewed: true })}
            disabled={isLoading}
            size="small">
            Проверено
          </StyledCheckedButton>
        </StyledButtonsWrapper>
        <StyledThreeDotWrapper size="small" onClick={handleMenuOpen} disabled={isLoading}>
          <MoreHorizIcon />
        </StyledThreeDotWrapper>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <MenuItem onClick={handleMenuClose} disabled={true}>
            Редактировать
          </MenuItem>
          {record.deletedAt ? (
            <MenuItem onClick={() => handleCardUpdate(record.id, { isDeleted: false })} style={{ color: 'green' }}>
              Восстановить
            </MenuItem>
          ) : (
            <MenuItem onClick={() => handleDelete(record.id, record)} style={{ color: 'red' }}>
              Удалить
            </MenuItem>
          )}
        </Menu>
      </StyledCardActionsWrapper>
      {/* Контент карточки */}
      <CardContentWrapper isDisabled={!!record.deletedAt}>
        {/* Колонка 1 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            Уникальный код
          </StyledTypography>
          <Typography>{record.uniqueCode}</Typography>
        </ColumnShort>

        {/* Колонка 2 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            ФИО
          </StyledTypography>
          <StyledTypography>{record.customer.fullName}</StyledTypography>
        </ColumnShort>

        {/* Колонка 3 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            Контакты
          </StyledTypography>
          <StyledTypography>{record.customer.phone}</StyledTypography>
          <StyledTypography>{record.customer.telegramId}</StyledTypography>
          <StyledTypography>{record.customer.email}</StyledTypography>
        </ColumnShort>

        {/* Колонка 4 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            Город
          </StyledTypography>
          <StyledTypography>{record.customer.city}</StyledTypography>
        </ColumnShort>

        {/* Колонка 5 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            Фото чека
          </StyledTypography>
          <ReceiptPhotoPreview
            receiptPhotoUrl={record.receiptPhotoUrl}
            onClick={() => setImageCropperOpen(!isImageCropperOpen)}
          />
        </ColumnShort>

        {/* Колонка 6 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            Дата покупки
          </StyledTypography>
          <StyledTypography>
            {record.purchaseDate
              ? new Intl.DateTimeFormat('ru-RU', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                }).format(new Date(record.purchaseDate))
              : 'Не указана'}
          </StyledTypography>
        </ColumnShort>

        {/* Колонка 7 */}
        <ColumnShort>
          <StyledTypography variant="subtitle2" fontWeight="bold">
            Дата конца гарантии
          </StyledTypography>
          <StyledTypography>
            {record.warrantyEndDate
              ? new Intl.DateTimeFormat('ru-RU', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                }).format(new Date(record.warrantyEndDate))
              : 'Не указана'}
          </StyledTypography>
          <StatusWrapper>
            <StatusChip
              isActive={record.warrantyStatus === 'active'}
              label={record.warrantyStatus === 'active' ? 'Активна' : 'Не активна'}
            />
          </StatusWrapper>
        </ColumnShort>

        {/* Колонка 8 */}
        <ColumnLong>
          <StyledTypography variant="subtitle2" fontWeight="bold" gutterBottom>
            Комментарии
          </StyledTypography>
          <CommentInput
            variant="outlined"
            placeholder="Оставьте комментарий"
            defaultValue={record.comments || ''}
            size="small"
            onBlur={event => handleCardUpdate(record.id, { comments: event?.target?.value || null })}
            disabled={isLoading}
            multiline
            rows={3}
          />
        </ColumnLong>
        {/* Модальное окно для кропа */}
        {isImageCropperOpen && (
          <ImageCropper imageUrl={record.receiptPhotoUrl} onClose={() => setImageCropperOpen(false)} ref={cropperRef} />
        )}
      </CardContentWrapper>
    </StyledCardWrapper>
  );
};
