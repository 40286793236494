import React from 'react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import styled from 'styled-components';

const StyledPreviewContainer = styled.div<{ hasImage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ hasImage }) => (hasImage ? 'transparent' : '#e0e0e0')};
  width: 50px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledIcon = styled(PhotoCameraIcon)`
  color: #9e9e9e;
  font-size: 24px;
`;

export const ReceiptPhotoPreview = ({
  receiptPhotoUrl,
  onClick,
}: {
  receiptPhotoUrl: string | null;
  onClick: () => void;
}) => {
  return (
    <StyledPreviewContainer hasImage={!!receiptPhotoUrl} onClick={onClick}>
      {receiptPhotoUrl ? <StyledImage src={receiptPhotoUrl} alt="Превью чека" /> : <StyledIcon />}
    </StyledPreviewContainer>
  );
};
